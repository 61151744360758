const vars = {
    // Media
    breakpoints: {
        xsMobile: 325,
        sMobile: 375,
        mobile: 450,
        mMobile: 550,
        lMobile: 650,
        mTablet: 768,
        tablet: 1024,
        sDesktop: 1366,
        mDesktop: 1440,
    },
    // Fonts
    fonts: {
        SFPro: "'SF Pro Display', Arial, sans-serif",
    },
    // Colors
    theme: {
        blue: '#3789FF',
        red: '#EA3F3F',
        black: '#282C30',
    },
}

export default vars
