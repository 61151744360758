import styled from 'styled-components'
import { useRef, useState, useEffect } from 'react'
import {} from '@/components'

export default function Page({ children }) {
    return (
        <StyledPage>
            <StyledContent>{children}</StyledContent>
        </StyledPage>
    )
}

const StyledPage = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 320px;
`

const StyledContent = styled.main`
    flex: 1 0 auto;
`
