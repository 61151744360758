import styled, { css, keyframes } from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'
import InputMask from 'react-input-mask'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const load = (color) => keyframes`
  0% {
    color: ${vars.theme.black};
  }
  
  50% {
    color: ${color};
  }
  
  100% {
    color: ${vars.theme.black};
  }
`

export const inputStyles = () => css`
    position: relative;
    width: 100%;
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid ${p => p.error ? "#EA3F3F" : "#CDDCEC"};
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    transition: all .2s;
    ${p => p.disabled && css`
        background: rgba(205, 220, 236, 0.5);
        border: 1px solid #CDDCEC;
    `}
    
    ${maxWidth(vars.breakpoints.mMobile)`
        padding: 12px;
        font-size: 15px;
    `}
`

const Input = styled.input`
    ${inputStyles}
`

Input.Mask = styled(InputMask)`
    ${inputStyles}
    
    ${p => p.code && css`
        ${maxWidth(vars.breakpoints.mMobile)`
            padding: 80px 20px;
            background: transparent;
            border: none;
            text-align: center;
            font-size: 25px;
            line-height: 32px;
            letter-spacing: calc(100vw / 20);
            animation: ${load(vars.theme.black)} 2s linear infinite ${p => !p.isLoading && "!important"};
            animation: ${load(vars.theme.blue)} 2s linear infinite ${p => p.isLoading && "!important"};
        `}
    `}
    
`

Input.Label = styled.label`
    margin-bottom: 8px;
    display: block;
    font-size: 14px;
    line-height: 20px;
`

Input.Error = styled.div`
    color: ${vars.theme.red};
`

Input.Wrapper = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 24px;
    
    &:after {
        content: '';
        position: absolute;
        right: -25px;
        bottom: 20px;
        width: 18px;
        height: 18px;
        border: 3px solid currentColor;
        border-left-color: transparent;
        border-radius: 50%;
        opacity: ${p => p.isLoading ? .8 : 0};
        transition: opacity 0.5s ease;
        pointer-events: none;
        animation: ${rotate} 1s linear infinite;
    }
    
    ${maxWidth(vars.breakpoints.mMobile)`
        margin-bottom: 16px;
        &:after {
            display: none;
        }
    `}
`

export default Input
