import styled, {css, keyframes} from 'styled-components'
import {maxWidth} from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Button = styled.button`
  position: relative;
  display: block;
  width: 100%;
  padding: 16px;
  box-shadow: 0px 2px 16px rgba(26, 70, 133, 0.12);
  border-radius: 6px;
  background: ${vars.theme.blue};
  color: #fff;
  &:hover {
    background: #1873F6;
  }
  &:active {
    background: #1873F6;
    box-shadow: none;
  }
  
  ${p => p.light && css`
    background: rgba(55, 137, 255, 0.15);
    box-shadow: none;
    color: ${vars.theme.blue};
    &:hover {
        background: rgba(55, 137, 255, 0.25);
    }
    &:active {
        background: rgba(55, 137, 255, 0.15);
        box-shadow: none;
    }
  `}
  
  &:disabled {
    background: rgba(205, 220, 236, 0.5);
    box-shadow: none;
    color: #81878C;
  }
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  cursor: pointer;
  transition: 0.25s ease-out;
      
    &::after {
        content: '';
        position: absolute;
        right: 15px;
        top: calc(50% - 9px);
        width: 18px;
        height: 18px;
        border: 3px solid currentColor;
        border-left-color: transparent;
        border-radius: 50%;
        opacity: ${p => p.isLoading ? 1 : 0};
        transition: opacity 0.5s ease;
        pointer-events: none;
        animation: ${rotate} 1s linear infinite;
    }
    
  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  ${maxWidth(vars.breakpoints.tablet - 1)`
    padding: 12px;
    font-size: 15px;
  `}
`

export default Button
