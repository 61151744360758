import styled, {css} from 'styled-components'
import {maxWidth} from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const Title = styled.div`
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
  
    ${maxWidth(vars.breakpoints.mMobile)`
        ${p => p.mobileNone && "display: none;"}
        margin-bottom: 8px;
        font-size: 25px;
        line-height: 32px;
    `}
`

export default Title
