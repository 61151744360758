import {useState, useRef, useEffect} from 'react'
import styled, { css } from 'styled-components'
import vars from '@/assets/styles/vars'
import { useLocale } from '@/contexts/LocaleContext'

export default () => {
    const { locale, locales, toggleLocale } = useLocale()
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef()

    useEffect(() => {
        window.addEventListener('click', onOutsideClick)
        return () => window.removeEventListener('click', onOutsideClick)
    }, [isOpen])


    const onOutsideClick = e => {
        if (isOpen && dropdownRef.current != e.target) {
            setIsOpen(false)
        }

    }

    return (
        <StyledSwitch>
            <StyledSwitch.Locale isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                {locale}
            </StyledSwitch.Locale>
            <StyledSwitch.Dropdown isOpen={isOpen} ref={dropdownRef}>
            {locales.map((item) => (
                <StyledSwitch.Lang key={item.locale} active={item.locale === locale} onClick={() => toggleLocale(item.locale)}>
                    {item.lang}
                </StyledSwitch.Lang>
            ))}
            </StyledSwitch.Dropdown>
        </StyledSwitch>
    )
}

const StyledSwitch = styled.div`
    position: relative;
`

StyledSwitch.Locale = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: 1px solid rgba(55, 137, 255, 0.15);
    border-radius: 24px;
    text-transform: capitalize;
    font-size: 14px;
    line-height: 16px;
    color: #3789FF;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
    border-color: #3789FF;
    }
    ${(p) =>
        p.isOpen
            && css`
                  border-color: #3789FF;
              `}
`

StyledSwitch.Dropdown = styled.div`
    position: absolute;
    top: 56px;
    right: 0;
    width: 180px;
    padding: 6px 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(22, 54, 98, 0.08);
    border-radius: 8px;
    z-index: 1;
    transition: all 0.3s;
    ${(p) =>
        p.isOpen
            ? css`
                  opacity: 1;
          visibility: visible;
          transform: none;
              `
            : css`
                  opacity: 0;
                  visibility: hidden;
                  transform: translateY(25px);
              `}
`

StyledSwitch.Lang = styled.div`
    padding: 10px 16px;
    transition: all 0.3s;
    cursor: pointer;
    ${(p) =>
        p.active
            ? css`
                  color: ${vars.theme.blue};
              `
            : css`
                  color: ${vars.theme.black};
              `}
`
