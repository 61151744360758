import { useRef, useState, useEffect } from 'react'
import { StyledSection, StyledContainer, StyledOrder, StyledButton, StyledForm } from '@/components/styles'
import { Radio, Input, PhoneInput, BackButton, OrderDetails } from '@/components'
import { DayPickerSingleDateController } from 'react-dates'
import moment from 'moment'
import { CSSTransition } from 'react-transition-group'
import getWindowWidth from '@/lib/getWindowWidth'
import { useLocale } from '@/contexts/LocaleContext'

export default ({ master, days, time, form, onBtnClick, goToPrevStep, fetchDays }) => {
    const { t } = useLocale()
    const windowWidth = getWindowWidth()
    const [step, setStep] = useState(1)
    const [focusedInput, setFocusedInput] = useState('startDate')
    const inputRef = useRef()

    useEffect(() => {
        form.values.time && inputRef.current && inputRef.current.focus()
    }, [form.values.time])

    return (
        <StyledSection type="order">
            <StyledContainer>
                <StyledOrder>
                    <BackButton
                        mobileText={step === 1 ? t('dateAndTime') : t('confirmation')}
                        onClick={step === 1 || windowWidth > 550 ? goToPrevStep : () => setStep(1)}
                    />

                    <CSSTransition
                        in={windowWidth > 550 || (windowWidth <= 550 && step === 1)}
                        timeout={200}
                        classNames="screen"
                        unmountOnExit
                    >
                        <div>
                            <StyledOrder.Block last={!form.values.date} radius mb>
                                <StyledOrder.BlockTitle mobileNone>{t('selectDate')}</StyledOrder.BlockTitle>
                                <StyledOrder.Calendar>
                                    <DayPickerSingleDateController
                                        date={form.values.date}
                                        isDayBlocked={(day) => !days.includes(moment(day).format('YYYY-MM-DD'))}
                                        isDayHighlighted={(day) => days.includes(moment(day).format('YYYY-MM-DD'))}
                                        keepOpenOnDateSelect={true}
                                        monthFormat="MMMM"
                                        numberOfMonths={windowWidth > 768 ? 2 : 1}
                                        orientation="horizontal"
                                        renderMonthElement={null}
                                        renderMonthText={(month) => moment(month).format('MMMM YYYY')}
                                        renderDayContents={(day) => moment(day).format('D')}
                                        onDateChange={(date) => form.setValues({ ...form.values, date })}
                                        focusedInput={focusedInput}
                                        onFocusChange={(focusedInput) => setFocusedInput(focusedInput || 'startDate')}
                                        onNextMonthClick={(date) =>
                                            fetchDays(
                                                windowWidth > 550
                                                    ? new Date(new Date(date).getFullYear(), new Date(date).getMonth() + 1, 1)
                                                    : date,
                                            )
                                        }
                                    />
                                </StyledOrder.Calendar>
                            </StyledOrder.Block>
                            <CSSTransition
                                in={!!form.values.date && !!time.length}
                                timeout={200}
                                classNames="on"
                                unmountOnExit
                            >
                                <StyledOrder.Block last={form.values.date} mb>
                                    <StyledOrder.BlockTitle>{t('selectTime')}</StyledOrder.BlockTitle>
                                    <Radio options={time} {...form.radio('time')} />
                                </StyledOrder.Block>
                            </CSSTransition>
                            {windowWidth <= 550 && (
                                <StyledOrder.ButtonWrapper visible={form.values.date && form.values.time}>
                                    <StyledButton onClick={() => setStep(2)}>{t('book')}</StyledButton>
                                </StyledOrder.ButtonWrapper>
                            )}
                        </div>
                    </CSSTransition>

                    <CSSTransition
                        in={windowWidth > 550 || (windowWidth <= 550 && step === 2)}
                        timeout={200}
                        classNames="screen"
                        unmountOnExit
                    >
                        <div>
                            {windowWidth <= 550 && (
                                <StyledContainer mobilePadding>
                                    <OrderDetails
                                        master={master}
                                        services={master.services.filter((item) =>
                                            form.values.services.includes(item.id),
                                        )}
                                        date={form.values.date}
                                        time={form.values.time}
                                    />
                                </StyledContainer>
                            )}
                            <CSSTransition in={!!form.values.time} timeout={200} classNames="on" unmountOnExit>
                                <StyledOrder.Block radius last>
                                    <StyledOrder.BlockTitle>{t('enterYourName')}</StyledOrder.BlockTitle>
                                    <StyledForm.Inner>
                                        <Input
                                            label={t('name')}
                                            placeholder={t('enterName')}
                                            inputRef={inputRef}
                                            {...form.input('first_name')}
                                        />
                                        <PhoneInput label={t('phone')} placeholder="+" {...form.input('phone')} />
                                    </StyledForm.Inner>
                                    <StyledOrder.ButtonWrapper visible={form.values.first_name && form.values.phone}>
                                        <StyledButton
                                            disabled={!form.values.first_name || !form.values.phone || form.isLoading}
                                            isLoading={form.isLoading}
                                            onClick={onBtnClick}
                                        >
                                            {windowWidth > 550 ? t('confirm') : t('bookAppointment')}
                                        </StyledButton>
                                    </StyledOrder.ButtonWrapper>
                                </StyledOrder.Block>
                            </CSSTransition>
                        </div>
                    </CSSTransition>
                </StyledOrder>
            </StyledContainer>
        </StyledSection>
    )
}
