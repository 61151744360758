import styled, {css} from 'styled-components'
import vars from '@/assets/styles/vars'
import {maxWidth} from '@/assets/styles/mixins.css.js'

const Order = styled.div`
    counter-reset: block; 
    
    .on-enter {
        opacity: 0;
        transform: translateY(10px);
    }
    
    .on-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 400ms, transform 400ms;
    }
    
    .on-exit {
        opacity: 1;
    }
    
    .on-exit-active {
        opacity: 0;
        transform: translateY(10px);
        transition: opacity 400ms, transform 400ms;
    }

    ${maxWidth(vars.breakpoints.mMobile)`
        & > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            min-height: calc(100vh - 56px);
        }
    `}
`

Order.Back = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 20px;
    color: #81878C;
    cursor: pointer;
    transition: all .2s;
    svg {
        margin-right: 8px;
        fill: #81878C;
        transition: all .2s;
    }
    &:hover {
        color: #282C30;
        svg {
            fill: #282C30;
        }
    }
    ${maxWidth(vars.breakpoints.mMobile)`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        justify-content: center;
        padding: 14px 12px;
        background: #EAF4FE;
        font-size: 15px;
        color: #282C30;
        z-index: 5;
        svg {
            position: absolute;
            top: 12px;
            left: 12px;
        }
    `}
`

Order.Block = styled.div`
    ${p => p.mb && "margin-bottom: 80px;"}
    ${maxWidth(vars.breakpoints.mMobile)`
        margin-bottom: 0;
        padding: 16px;
        ${p => p.last ? css`
            flex: 1;
            padding-bottom: 90px;
        ` : css`margin-bottom: 8px;`}
        ${p => p.radius && "border-radius: 16px 16px 0px 0px;"}
        background: #fff;
        transition: all .2s;
    `}
`

Order.BlockTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    &:before {
        content: counter(block);
        counter-increment: block;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        margin-right: 16px;
        background: #DDEAF8;
        border-radius: 50%;
    }
    ${maxWidth(vars.breakpoints.mMobile)`
        ${p => p.mobileNone && "display: none;"}
        margin-bottom: 16px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        &:before {
            display: none;
        }
    `}
`

Order.Calendar = styled.div`
    margin-top: -22px;
    ${maxWidth(vars.breakpoints.mMobile)`
        ${p => p.grey && css`
            font-size: 13px;
            line-height: 20px;
        `}
    `}
`

Order.CodeWrapper = styled.div`
    width: 200px;
    ${maxWidth(vars.breakpoints.mMobile)`
        width: 100%;
    `}
`

Order.ButtonWrapper = styled.div`
    ${maxWidth(vars.breakpoints.mMobile)`
        position: fixed;
        bottom: 32px;
        left: 0;
        right: 0;
        padding: 0 16px;
        transition: all .3s;
        ${p => p.visible ? css`
            transform: none;
        ` : css`
            transform: translateY(90px);
        `}
    `}
`

export default Order
