import styled from 'styled-components'
import { SvgIcon } from '@/components'
import { useLocale } from '@/contexts/LocaleContext'

export default () => {
    const { locale } = useLocale()

    return (
        <StyledLinks>
            <StyledLinks.Link href={`https://play.google.com/store/apps/details?id=com.easyservice.easystyle&hl=${locale}`} target="_blank">
                <SvgIcon name="google-play" width="135" height="40" />
            </StyledLinks.Link>
            <StyledLinks.Link href="https://apps.apple.com/app/easy-style/id1466268556" target="_blank">
                <SvgIcon name="apple-store" width="120" height="40" />
            </StyledLinks.Link>
        </StyledLinks>
    )
}

const StyledLinks = styled.div`
    display: flex;
    justify-content: center;
`

StyledLinks.Link = styled.a`
    margin: 0 8px;
`
