import styled, { css } from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css'
import vars from '@/assets/styles/vars'

const Container = styled.div`
    min-width: 280px;
    width: 100%;
    max-width: 792px;
    padding: 0 32px;
    ${(p) =>
        p.sizeL &&
        css`
            max-width: 1440px;
        `}
    ${(p) =>
        p.sizeS &&
        css`
            max-width: 450px;
        `}
    margin-left: auto;
    margin-right: auto;

    ${maxWidth(vars.breakpoints.mMobile)`
      max-width: 100%;
      padding: ${p => p.mobilePadding ? "0 16px" : 0};
    `}
`

export default Container
