import { StyledRadio } from '@/components/styles'

export default function ({ options, value, onChange }) {
    return (
        <StyledRadio>
            {options.map((item) => (
                <StyledRadio.Label checked={value == item} key={item}>
                    <input type="radio" onChange={() => onChange(item)} checked={value == item} />
                    {item}
                </StyledRadio.Label>
            ))}
        </StyledRadio>
    )
}
