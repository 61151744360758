import { StyledInput } from '@/components/styles'
import { InputWrapper } from '@/components'
import getWindowWidth from '@/lib/getWindowWidth'

export default function ({ label, error, value, placeholder, onChange, onClick, inputRef, ...props }) {
    const windowWidth = getWindowWidth()

    return (
        <InputWrapper label={label} error={error} {...props}>
            <StyledInput.Mask
                placeholder={placeholder}
                value={value}
                type="tel"
                mask="+999999999999"
                maskChar=""
                onChange={(e) => onChange(e.target.value)}
                onClick={onClick}
                error={error}
                {...props}
            >
                {(inputProps) => <input ref={inputRef} {...inputProps} />}
            </StyledInput.Mask>
        </InputWrapper>
    )
}
