import styled, {css} from 'styled-components'
import vars from '@/assets/styles/vars'
import {maxWidth} from '@/assets/styles/mixins.css.js'

const Order = styled.div`
    margin-bottom: 24px;
`

Order.Block = styled.div`
    &:not(:last-child) {
        margin-bottom: 8px;
    }
    padding: 16px;
    background: #DDEAF8;
    border-radius: 8px;
`

Order.Master = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    ${maxWidth(vars.breakpoints.mMobile)`
        font-size: 15px;
    `}
`

Order.MasterPhoto = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    border-radius: 50%;
    overflow: hidden;
    img {
        object-fit: cover;
    }
    ${maxWidth(vars.breakpoints.mMobile)`
        width: 24px;
        height: 24px;
    `}
`

Order.Service = styled.div`
    &:not(:last-child) {
        padding-bottom: 16px;
        border-bottom: 1px solid #CDDCEC;
    }
    &:last-child {
        padding-top: 16px;
        padding-bottom: 0;
    }
    &:first-child {
        padding-top: 0;
    }
`

Order.Date = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    ${maxWidth(vars.breakpoints.mMobile)`
        font-size: 15px;
    `}
`

Order.FailedButton = styled.div`
    width: 240px;
    ${maxWidth(vars.breakpoints.mMobile)`
        width: 100%;
    `}
`

Order.Logo = styled.div`
    display: none;
    font-size: 0;
    margin-bottom: 16px;
    ${maxWidth(vars.breakpoints.mMobile)`
        display: block;
        svg {
            width: 57px;
            height: 40px;
        }
    `}
`

export default Order
