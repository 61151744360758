import styled, {css} from 'styled-components'
import vars from '@/assets/styles/vars'
import {maxWidth} from '@/assets/styles/mixins.css.js'

const Master = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

Master.Photo = styled.div`
    display: flex;
    width: 100px;
    height: 100px;
    margin-bottom: 16px;
    border-radius: 50%;
    overflow: hidden;
    img {
        object-fit: cover;
    }
    ${maxWidth(vars.breakpoints.mMobile)`
        width: 72px;
        height: 72px;
    `}
`

Master.Name = styled.div`
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    ${maxWidth(vars.breakpoints.mMobile)`
        margin-bottom: 24px;
        font-size: 17px;
    `}
`

Master.Messengers = styled.div`
    display: flex;
    margin-bottom: 32px;
    ${maxWidth(vars.breakpoints.mMobile)`
        margin-bottom: 24px;
    `}
`

Master.Messenger = styled.a`
    margin: 0 16px;
`

Master.Services = styled.div`
    ${maxWidth(vars.breakpoints.mMobile)`
        min-height: calc(100vh - 285px);
        padding: 4px 0 ${ p => p.pLarge ? "80px" : "20px"};
        background: #FFFFFF;
        border-radius: 16px 16px 0px 0px;
        overflow: hidden;
        transition: all .3s;
    `}
`

export default Master
