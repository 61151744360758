import styled, {css} from 'styled-components'
import {maxWidth} from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const Radio = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
    input {
        position: absolute;
        left: -9999px;
    }
    ${maxWidth(vars.breakpoints.mTablet)`
        margin: -4px;
    `}
`

Radio.Label = styled.label`
    position: relative;
    width: 74px;
    padding: 12px 10px;
    margin: 8px;
    background: ${p => p.checked ? vars.theme.blue : "transparent"};
    border: 1px solid ${p => p.checked ? vars.theme.blue : "#CDDCEC"};
    border-radius: 8px;
    color: ${p => p.checked ? "#fff" : "#282C30"};
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    transition: .2s;
    
    ${maxWidth(vars.breakpoints.mTablet)`
        width: 64px;
        margin: 4px;
        padding: 10px 6px;
    `}
`

export default Radio
