import React from 'react'
import Logo from '../assets/icons/logo.svg'
import Whatsapp from '../assets/icons/whatsapp.svg'
import Viber from '../assets/icons/viber.svg'
import Arrow from '../assets/icons/arrow.svg'
import MobileArrow from '../assets/icons/mobile-arrow.svg'
import GooglePlay from '../assets/icons/google-play.svg'
import AppleStore from '../assets/icons/apple-store.svg'

const icons = {
    logo: Logo,
    whatsapp: Whatsapp,
    viber: Viber,
    arrow: Arrow,
    'mobile-arrow': MobileArrow,
    'google-play': GooglePlay,
    'apple-store': AppleStore,
}

export default function ({ name, width, height }) {
    return icons[name] ? React.createElement(icons[name], { width, height }) : null
}
