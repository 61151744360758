import { StyledInput } from '@/components/styles'

export default function ({ children, label, error, ...props }) {
    return (
        <StyledInput.Wrapper {...props}>
            {label && <StyledInput.Label>{label}</StyledInput.Label>}
            {children}
            {error && <StyledInput.Error>Данные введены некорректно</StyledInput.Error>}
        </StyledInput.Wrapper>
    )
}
