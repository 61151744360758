import { useRef, useEffect } from 'react'
import NProgress from 'nprogress'
import Router from 'next/router'
import vars from '@/assets/styles/vars'

export default function NextNProgress({
    color = vars.theme.blue,
    startPosition = 0.3,
    stopDelayMs = 200,
    height = 3,
    options = { showSpinner: false, speed: 250, trickleSpeed: 200 },
}) {
    let timer = useRef(null)

    const routeChangeStart = () => {
        NProgress.set(startPosition)
        NProgress.start()
    }

    const routeChangeEnd = () => {
        clearTimeout(timer.current)
        timer.current = setTimeout(() => {
            NProgress.done(true)
        }, stopDelayMs)
    }

    const routeChangeError = (err, url) => {
        if (err.cancelled) {
            console.warn(`Route to ${url} was cancelled!`)
        }
    }

    useEffect(() => {
        if (options) {
            NProgress.configure(options)
        }

        Router.events.on('routeChangeStart', routeChangeStart)
        Router.events.on('routeChangeComplete', routeChangeEnd)
        Router.events.on('routeChangeError', routeChangeError)

        return () => {
            Router.events.off('routeChangeStart', routeChangeStart)
            Router.events.off('routeChangeComplete', routeChangeEnd)
            Router.events.off('routeChangeError', routeChangeError)
            clearTimeout(timer.current)
        }
    }, [])

    return (
        <style jsx global>{`
            #nprogress {
                pointer-events: none;
            }

            #nprogress .bar {
                position: fixed;
                z-index: 1031;
                top: 0;
                left: 0;
                width: 100%;
                height: ${height}px;
                border-radius: 5px;
                background: ${color};
            }

            #nprogress .peg {
                position: absolute;
                right: 0px;
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 5px;
                opacity: 1;
            }
        `}</style>
    )
}
