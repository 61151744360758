import { StyledOrderDetails, StyledService } from '@/components/styles'
import moment from 'moment'

export default ({ master, services, date, time }) => {
    return (
        <StyledOrderDetails>
            <StyledOrderDetails.Block>
                <StyledOrderDetails.Master>
                    <StyledOrderDetails.MasterPhoto>
                        <img src={master.avatar.sizes.mini} alt="" />
                    </StyledOrderDetails.MasterPhoto>
                    <span>
                        {master.first_name} {master.last_name}
                    </span>
                </StyledOrderDetails.Master>
            </StyledOrderDetails.Block>
            <StyledOrderDetails.Block>
                {services.map((service) => (
                    <StyledOrderDetails.Service key={service.id}>
                        <StyledService.Inner>
                            <StyledService.Title>{service.title}</StyledService.Title>
                            <StyledService.Time>{service.duration}</StyledService.Time>
                            <StyledService.Price>
                                {service.price} {master.currency.short_name}
                            </StyledService.Price>
                        </StyledService.Inner>
                    </StyledOrderDetails.Service>
                ))}
            </StyledOrderDetails.Block>
            <StyledOrderDetails.Block>
                <StyledOrderDetails.Date>
                    {moment(date).format('DD MMMM YYYY')} {time}
                </StyledOrderDetails.Date>
            </StyledOrderDetails.Block>
        </StyledOrderDetails>
    )
}
