import { StyledSection, StyledContainer, StyledTitle } from '@/components/styles'
import { useLocale } from '@/contexts/LocaleContext'

export default () => {
    const { t } = useLocale()

    return (
        <StyledSection pt="large" pb="large">
            <StyledContainer mobilePadding>
                <StyledTitle>{t('errorPage')}</StyledTitle>
            </StyledContainer>
        </StyledSection>
    )
}
