import styled, { css } from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const Form = styled.form`
  
`

Form.Inner = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    ${maxWidth(vars.breakpoints.mTablet)`
        display: block;
    `}
`

Form.Info = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    color: ${vars.theme.darkBlue};
    svg {
        margin-right: 12px;
    }
`

Form.Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    ${maxWidth(vars.breakpoints.mTablet)`
        flex-direction: column-reverse;
        align-items: flex-start;
    `}
`

export default Form
