import React, { useContext, useState, useEffect } from 'react'
import translations from '@/lib/translations'
import cookies from '@/lib/cookies'
import moment from 'moment'
import 'moment/locale/ru'

const LocaleContext = React.createContext()

export function LocaleProvider({ children, masterLocale = 'ru' }) {
    const locales = [
        {locale: 'ru', lang: 'Русский'},
        {locale: 'en', lang: 'English'},
        {locale: 'et', lang: 'Eesti'},
    ]
    const [locale, setLocale] = useState(masterLocale)

    moment.locale(locale)

    function translate(key) {
        return translations[locale][key] || ''
    }

    function toggleLocale(locale) {
        cookies.set('es_lang', locale)
        setLocale(locale)
    }

    useEffect(() => {
        toggleLocale(masterLocale)
    }, [])

    return (
        <LocaleContext.Provider value={{ locale, locales, toggleLocale, t: translate }}>
            {children}
        </LocaleContext.Provider>
    )
}

export function useLocale() {
    return useContext(LocaleContext)
}
