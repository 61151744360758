import styled, {css} from 'styled-components'
import {maxWidth} from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const Text = styled.div`
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 28px;
  
    ${maxWidth(vars.breakpoints.mMobile)`
        font-size: 15px;
        line-height: 20px;
    `}
`

export default Text
