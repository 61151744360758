import { useRef, useState, useEffect } from 'react'
import { StyledService } from '@/components/styles'

export default ({ checked, setChecked, service, currency }) => {
    return (
        <StyledService onClick={() => setChecked(service.id)}>
            <StyledService.Checkbox checked={checked} />
            <StyledService.Inner>
                <StyledService.Title>{service.title}</StyledService.Title>
                <StyledService.Time>{service.duration}</StyledService.Time>
                <StyledService.Price>
                    {service.price} {currency}
                </StyledService.Price>
            </StyledService.Inner>
        </StyledService>
    )
}
