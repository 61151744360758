export default {
    ru: {
        book: 'Записаться',
        back: 'Назад',
        selectDate: 'Выберите дату',
        selectTime: 'Выберите время',
        enterYourName: 'Добавьте имя и номер телефона для подтверждения записи',
        confirm: 'Подтвердить',
        smsCode: 'SMS-код',
        smsCodeWasSent1: 'На номер',
        smsCodeWasSent2: 'был выслан SMS-код. Введите его, чтобы подтвердить номер телефона.',
        bookedSuccessfully: 'Запись подтверждена!',
        bookedSuccessfullyFull:
            'Вы были успешно записаны. Чтобы узнать подробнее, изменить или отменить запись воспользуйтесь мобильным приложением.',
        somethingWrong: 'Что-то пошло не так!',
        failedToBook: 'Нам не удалось записать вас. Попробуйте записаться еще раз.',
        tryAgain: 'Попробовать снова',
        dateAndTime: 'Дата и время',
        confirmation: 'Подтверждение',
        bookAppointment: 'Подтвердить запись',
        name: 'Имя',
        enterName: 'Введите имя',
        phone: 'Телефон',

        metaTitle: 'Easy Style - Скачать приложение | Мастера красоты в вашем городе',
        title: 'Мобильное приложение для поиска мастеров красоты в вашем городе',
        conditions: [
            'Онлайн запись через приложение, никаких звонков',
            'Удобное управление своим расписанием',
            'Автоматическое SMS-оповещение о записи',
            'Поиск мастеров рядом с Вами',
        ],

        errorPage: 'Онлайн запись недоступна. У мастера не оплачена подписка.',
    },
    en: {
        book: 'Book',
        back: 'Back',
        selectDate: 'Select date',
        selectTime: 'Choose time',
        enterYourName: 'Enter your name and phone number to book an appointment',
        confirm: 'Confirm',
        smsCode: 'SMS-code',
        smsCodeWasSent1: 'SMS-code was sent to',
        smsCodeWasSent2: '. Enter this code to book an appointment.',
        bookedSuccessfully: 'Your appointment was booked successfully!',
        bookedSuccessfullyFull:
            'Your appointment was booked successfully. Use our mobile app to change or cancel your appointment.',
        somethingWrong: 'Something went wrong!',
        failedToBook: 'Failed to book an appointment. Try again.',
        tryAgain: 'Try again',
        dateAndTime: 'Date and time',
        confirmation: 'Confirmation',
        bookAppointment: 'Book appointment',
        name: 'Name',
        enterName: 'Enter name',
        phone: 'Phone',

        metaTitle: 'Easy Style - Download mobile app | Book beauty services online',
        title: 'Mobile app for beauty masters in your city',
        conditions: [
            'Online booking via the app, no more calls',
            'Convenient management of your schedule',
            'Appointment notifications for clients',
            'Search for beauty masters near you',
        ],

        errorPage: 'Online booking is not available. This master has no subscription.',
    },
    et: {
        book: 'Broneerida aeg',
        back: 'Tagasi',
        selectDate: 'Valige kuupäev',
        selectTime: 'Valige kellaaeg',
        enterYourName: 'Sisestage nimi ja telefoninumber',
        confirm: 'Kinnitage tegu',
        smsCode: 'SMS-kood',
        smsCodeWasSent1: 'Telefoninumbriga',
        smsCodeWasSent2: 'kinnitamise kood oli saadetud.',
        bookedSuccessfully: 'Broneeringu kinnitatud!',
        bookedSuccessfullyFull:
            'Broneering on kinnitatud. Kasutage mobiilirakendus, et rohkem teada saada, muuta või tühistada oma broneering.',
        somethingWrong: 'Midagi läks valesti!',
        failedToBook: 'Broneerimine ebaõnnestus. Palun proovi uuesti.',
        tryAgain: 'Proovin uuesti',
        dateAndTime: 'Kuupäev ja kellaaeg',
        confirmation: 'Kinnitage tegu',
        bookAppointment: 'Kinnitage broneeritud aja',
        name: 'Nimi',
        enterName: 'Sisestage nimi',
        phone: 'Telefoninumber',
        metaTitle: 'Easy Style - Laadige alla mobiilirakendus | Meistrid ilu teie linnas',
        title: 'Mobiilirakendus leida meistrid ilu teie linnas',
        conditions: [
            'Online broneerimine kaudu mobiilnerakendus, mingit kõned',
            'Mugav kontrolli oma ajakava',
            'Automaatne SMS-teade klientidele',
            'Otsige läheduses meistreid',
        ],
        errorPage: 'Online broneerimine ei ole saadaval. Subscription pole aktiveeritud.',
    },
}
