import { StyledOrder } from '@/components/styles'
import { SvgIcon } from '@/components'
import getWindowWidth from '@/lib/getWindowWidth'
import { useLocale } from '@/contexts/LocaleContext'

export default ({ onClick, mobileText }) => {
    const windowWidth = getWindowWidth()
    const { t } = useLocale()

    return (
        <StyledOrder.Back onClick={onClick}>
            <SvgIcon name={windowWidth > 550 ? 'arrow' : 'mobile-arrow'} width="24" height="24" />
            <span>{windowWidth > 550 ? t('back') : mobileText}</span>
        </StyledOrder.Back>
    )
}
