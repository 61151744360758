import Head from 'next/head'

export default function CustomHead() {
    return (
        <Head>
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="format-detection" content="telephone=no" />

            <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
            <link rel="manifest" href="/favicon/site.webmanifest" />
            <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#3789ff" />
            <link rel="shortcut icon" href="/favicon/favicon.ico" />
            <meta name="msapplication-TileColor" content="#eaf4fe" />
            <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
            <meta name="theme-color" content="#eaf4fe" />

            <title key="page">Easy style</title>

            <meta property="og:title" content="Easy style" key="title" />
            <meta property="og:type" content="website" />
            <meta property="og:description" content="Easy style" key="ogDescription" />
            <meta property="description" content="Easy style" key="description" />

            <meta property="og:image" content="/images/og-image.jpg"/>
            <meta property="og:image:width" content="279"/>
            <meta property="og:image:height" content="279"/>
        </Head>
    )
}
