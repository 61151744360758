import styled, {css} from 'styled-components'
import vars from '@/assets/styles/vars'
import {maxWidth} from '@/assets/styles/mixins.css.js'

const Service = styled.div`
    display: flex;
    margin-bottom: 16px;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(29, 72, 134, 0.08);
    border-radius: 8px;
    cursor: pointer;
    ${maxWidth(vars.breakpoints.mMobile)`
        position: relative;
        margin: 0;
        padding: 20px 16px;
        box-shadow: none;
        
        &:not(:last-child) {
            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                right: 16px;
                height: 1px;
                width: calc(100% - 68px);
                background: #CDDCEC;
            }
        }
    `}
`

Service.Checkbox = styled.div`
    position: relative;
    display: flex;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    background: #FFFFFF;
    border: 1px solid #CDDCEC;
    box-sizing: border-box;
    border-radius: 4px;
    &:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 8px;
        width: 7px;
        height: 12px;
        border-bottom: 2px solid ${vars.theme.blue};
        border-right: 2px solid ${vars.theme.blue};
        transform: rotate(45deg);
        opacity: ${p => p.checked ? 1 : 0};
        transition: .2s;
    }
    
    ${maxWidth(vars.breakpoints.mMobile)`
        margin-right: 12px;
    `}
`

Service.Inner = styled.div`
    flex: 1;
    display: grid;
    grid-template-areas: 'title time' 'price price';
    gap: 8px;
    justify-content: space-between;
    ${maxWidth(vars.breakpoints.mMobile)`
        gap: 4px 8px;
    `}
`

Service.Title = styled.div`
    grid-area: title;
    font-size: 18px;
    line-height: 24px;
    ${maxWidth(vars.breakpoints.mMobile)`
        font-size: 17px;
    `}
`

Service.Time = styled.div`
    grid-area: time;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: rgba(40, 44, 48, 0.5);
    ${maxWidth(vars.breakpoints.mMobile)`
        font-size: 15px;
    `}
`

Service.Price = styled.div`
    grid-area: price;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    ${maxWidth(vars.breakpoints.mMobile)`
        font-size: 17px;
    `}
`

export default Service
