import styled from 'styled-components'
import vars from '@/assets/styles/vars'
import { maxWidth } from '@/assets/styles/mixins.css.js'
import { useRef, useState, useEffect } from 'react'
import { StyledSection, StyledContainer, StyledMaster, StyledButton, StyledOrder } from '@/components/styles'
import { SvgIcon, Service, LangSwitch } from '@/components'
import { useLocale } from '@/contexts/LocaleContext'

export default ({ master, form, onBtnClick }) => {
    const { t } = useLocale()

    const { avatar } = master || {}

    return (
        <>
            <StyledSection type="header" absolute>
                <StyledContainer sizeL mobilePadding>
                    <Header>
                        <Logo>
                            <SvgIcon name="logo" width="80" height="56" />
                        </Logo>
                        <LangSwitch />
                    </Header>
                </StyledContainer>
            </StyledSection>
            <StyledSection type="master">
                <StyledContainer sizeS>
                    <StyledMaster>
                        <StyledMaster.Photo>
                            {avatar && <img src={master.avatar.sizes.mini} alt="" /> }
                        </StyledMaster.Photo>
                        <StyledMaster.Name>
                            {master.first_name} {master.last_name}
                        </StyledMaster.Name>
                        {!!master.master_messengers?.length && (
                            <StyledMaster.Messengers>
                                {master.master_messengers.find((item) => item.type == 'whatsapp') && (
                                    <StyledMaster.Messenger
                                        href={`https://wa.me/${
                                            master.master_messengers.find((item) => item.type == 'whatsapp').phone
                                        }`}
                                    >
                                        <SvgIcon name="whatsapp" width="48" height="48" />
                                    </StyledMaster.Messenger>
                                )}
                                {master.master_messengers.find((item) => item.type == 'viber') && (
                                    <StyledMaster.Messenger
                                        href={`viber://chat?number=%2B${
                                            master.master_messengers.find((item) => item.type == 'viber').phone
                                        }`}
                                    >
                                        <SvgIcon name="viber" width="48" height="48" />
                                    </StyledMaster.Messenger>
                                )}
                            </StyledMaster.Messengers>
                        )}
                    </StyledMaster>
                    <StyledMaster.Services pLarge={!!form.values.services.length}>
                        {master.services.map((item) => (
                            <Service
                                key={item.id}
                                service={item}
                                currency={master.currency.short_name}
                                {...form.checkboxArray('services', item.id)}
                            />
                        ))}
                    </StyledMaster.Services>
                    <StyledOrder.ButtonWrapper visible={!!form.values.services.length}>
                        <StyledButton
                            disabled={!form.values.services.length || form.isLoading}
                            isLoading={form.isLoading}
                            onClick={onBtnClick}
                        >
                            {t('book')}
                        </StyledButton>
                    </StyledOrder.ButtonWrapper>
                </StyledContainer>
            </StyledSection>
        </>
    )
}

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Logo = styled.div`
    font-size: 0;
    svg {
        width: 80px;
        height: 56px;
    }
    ${maxWidth(vars.breakpoints.mMobile)`
        svg {
            width: 57px;
            height: 40px;
        }
    `}
`
