import { maxWidth } from '@/assets/styles/mixins.css.js'
import styled, { css } from 'styled-components'
import vars from '@/assets/styles/vars'

const Section = styled.section`
  ${(props) =>
      props.overflowXHidden &&
      css`
          overflow-x: hidden;
      `}
      
  ${(props) =>
      props.relative &&
      css`
          position: relative;
          z-index: 1;
      `}
      
  ${(props) =>
      props.absolute &&
      css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          ${maxWidth(vars.breakpoints.mMobile)`
            position: static;
          `}
      `}
    
  ${({ type }) => {
      switch (type) {
          case 'header':
              return css`
                  padding-top: 32px;
                  padding-bottom: 0;

                  ${maxWidth(vars.breakpoints.mMobile)`
                    padding-top: 16px;
                    padding-bottom: 0;
                  `}
              `
          case 'master':
              return css`
                  padding-top: 48px;
                  padding-bottom: 48px;

                  ${maxWidth(vars.breakpoints.mMobile)`
                    padding-top: 26px;
                    padding-bottom: 0;
                  `}
              `
          case 'order':
              return css`
                  padding-top: 32px;
                  padding-bottom: 48px;

                  ${maxWidth(vars.breakpoints.mMobile)`
                    padding-top: 56px;
                    padding-bottom: 0;
                  `}
              `
          case 'code':
              return css`
                  padding-top: 32px;
                  padding-bottom: 48px;

                  ${maxWidth(vars.breakpoints.mMobile)`
                    padding-top: 72px;
                    padding-bottom: 0;
                  `}
              `
          case 'result':
              return css`
                  padding-top: 64px;
                  padding-bottom: 64px;

                  ${maxWidth(vars.breakpoints.mMobile)`
                    padding-top: 32px;
                    padding-bottom: 92px;
                  `}
              `
          default:
              return css`
                  padding-top: 32px;
                  padding-bottom: 0;

                  ${maxWidth(vars.breakpoints.mMobile)`
                    padding-top: 16px;
                    padding-bottom: 0;
                    
                `}
              `
      }
  }}
`

export default Section
