import { StyledInput } from '@/components/styles'
import { InputWrapper } from '@/components'

export default function ({ label, type, error, value, placeholder, onChange, onClick, inputRef }) {
    return (
        <InputWrapper label={label} error={error}>
            <StyledInput
                type={type ? type : 'text'}
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(type === 'tel' ? e.target.value.replace(/[^+\d]/g, '') : e.target.value)}
                onFocus={() => type === 'tel' && !value && onChange('+')}
                onClick={onClick}
                error={error}
                ref={inputRef}
            />
        </InputWrapper>
    )
}
